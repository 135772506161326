<template>
<div class="card py-4 px-4 w-64 2xl:w-72 gap-3 2xl:gap-4">
    <div class="info rounded-xl h-10 2xl:h-12 pl-4">
        <div class="info__controls">
            <CheckboxSide
                class="rounded-l-xl w-4 2xl:w-5 p-1"
                @click.native.stop
                @change="$emit('clicked', data.id)"
                :value="data.checked"
            />
        </div>
        <div class="info__general pl-3.5 pr-2.5 cursor-pointer  gap-2" @click="$emit('clicked',data.id)">
            <div class="label gap-1 ">
                <div class="name text-sm truncate">{{ data.name }}</div>
                <div class="icons gap-1">
                    <!-- <div class="count text-xxs 2xl:text-xs w-3.5 h-3.5 2xl:w-4 2xl:h-4">{{ data.notofocations_count }}</div> -->
                </div>
            </div>
            <div class="status">
                <SelectColored
                    class="text-xxs 2xl:text-xs w-24 h-6 flex items-center"
                    @click.native.stop
                    :options="statusList"
                    :value="data.status"
                    @input="$emit('statusChanged',$event, data.id)"
                />
            </div>
        </div>
    </div>
    <div class="actions gap-1">
        <div
            class="actions__item item--email"
            @click.stop="$emit('email')"
            title="Email"
        >
            <emailIcon class="icon w-1/2 h-1/2"/>
        </div>
        <div
            class="actions__item item--phone"
            @click.stop="$emit('logCall')"
            title="Call"
        >
            <phoneIcon class="icon w-1/2 h-1/2"/>
        </div>
        <div
            class="actions__item item--write"
            @click.stop="$emit('note')"
            title="Note"
        >
            <writingIcon class="icon w-1/2 h-1/2"/>
        </div>
        <div
            class="actions__item item--task"
            @click.stop="$emit('task')"  
            title="Task"  
        >
            <taskIcon class="icon w-1/2 h-1/2"/>
        </div>
        <div
            class="actions__item item--history"
            @click.stop="$emit('history')"
            title="History"
        >
            <historyIcon class="icon w-1/2 h-1/2"/>
        </div>
        <!-- <div
            class="actions__item item--others"
            @click.stop="$emit('other')"    
        >
            <othersIcon class="icon w-1/2 h-1/2"/>
        </div> -->
    </div>
</div>
</template>

<script>
import CheckboxSide from "@/components/sc-journey/checkbox-side.vue";
import SelectColored from "@/components/sc-journey/select-colored";

import phoneIcon from '@/assets/icons/sc-journey/phone.svg';
import emailIcon from '@/assets/icons/sc-journey/email.svg';
import writingIcon from '@/assets/icons/sc-journey/writing.svg';
import taskIcon from '@/assets/icons/sc-journey/task.svg';
import historyIcon from '@/assets/icons/sc-journey/history.svg';
// import othersIcon from '@/assets/icons/sc-journey/others.svg';

export default {
    name: 'card-short',
    components: {
        phoneIcon,
        emailIcon,
        writingIcon,
        taskIcon,
        historyIcon,
        // othersIcon,
        SelectColored,
        CheckboxSide,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        statusList: {
            type: Array,
            required: true,
        },
        selected: {
            type: String,
            default: '',
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.card {
    background-color: #fff;
    display: flex;
    align-items: center;
    overflow: visible;
    flex-direction: column;
    align-items: stretch;
    .info {
        background-color: #DFDFDF;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        &__controls {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
        }
        &__general {
            flex-grow: 1;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            max-width: 100%;
            .label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                align-self: stretch;
                padding: 0;
                overflow: hidden;
                .name {
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .icons {
                    display: inline-flex;
                    align-items: center;
                    transform: translateY(-50%);
                    .count {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-weight: bold;
                        border-radius: 50%;
                        background-color: #0D69D5;
                    }
                }
            }
        }
    }
    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #668AB5;
            border-radius: 50%;
            cursor: pointer;
            flex-shrink: 0;
            @apply h-8 w-8 2xl:h-9 2xl:w-9;
            .icon {
                color: white;
                path {
                    fill: #fff;
                }
            }
        }
    }
}
::v-deep {
    .multiselect {
        .multiselect__single, .multiselect__option {
            font-size: 10px;
        }
    }
}
</style>