<template>
    <ModalContent name="mail" ref="modal" width="75%" height="75%" class="rounded-full" v-bind="$attrs" v-on="$listeners"
        :showFooter="false">
        <template #title>
            <MailIcon />
            Email
        </template>
        <template>
            <div class="flex flex-col justify-between flex-grow overflow-y-auto bg-white bg-card-bg">
                <div class="flex justify-end pt-4 pr-6 gap-4">
                    <neo-button text="Template" type="primary"
                        class="send-mail-btn w-32 text-sm h-9 font-semibold rounded-xl px-6" @click="browseToggleModal" />
                    <neo-button text="Sequence" type="button"
                        class="send-mail-btn w-32 text-sm h-9 font-semibold rounded-xl  px-6"
                        @click="browseSeqToggleModal" />
                </div>
                <FormulateForm class="flex flex-col h-full overflow-y-auto  email-form-container">
                    <div class="flex-grow container pr-6 w-full max-w-full overflow-hidden scroll-bar">
                        <!-- form ui -->
                        <div class="email-form-wrapper h-full w-full flex flex-col gap-4 py-4">
                            <!-- <div class="flex items-center justify-end gap-2"></div> buttons to be added in this div-->
                            <div class="header-inputs flex justify-between items-start gap-4">
                                <div class="inputs-wrapper flex flex-col w-full gap-4">
                                    <div class="input-container flex items-start">
                                        <div class="label-part w-28 h-12 gray-bg pl-5 flex items-center relative">
                                            <p class="m-0 p-0 text-sm font-medium">To</p>
                                            <div
                                                class="icon-part absolute grid place-content-center -right-8 h-16 w-16 gray-bg z-10 border-4 border-white rounded-full">
                                                <ToIcon />
                                            </div>
                                        </div>

                                        <div class="input-part flex-1 max-h-12 relative rounded-r-full" style="background-color: #f2f2f2">
                                            <FormulateInput validation="required" class="pr-36 w-full h-12  overflow-y-auto scroll-bar relative email_to" type="pills" 
                                                elementLabel="email" 
                                                elementTooltip="type"
                                                placeholder="Email address" v-model="values.email_to" 
                                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" >
                                                <template #errors>
                                                    <span v-if="actions.isClicked && !values.email_to.length"
                                                        class="text-xs font-light leading-6 ml-5"
                                                        style="color: rgb(150, 5, 5);"> Email is required </span>
                                                </template>
                                            </FormulateInput>

                                            <div class="h-full pr-5 absolute right-2 top-0 flex items-center gap-4"
                                                @click="toggleRoles">
                                                <div class="relative" v-on-click-outside="closeList">
                                                    <div class="dropdown-btn flex cursor-pointer items-center">
                                                        <div
                                                            class="capitalize inline-flex gap-2 items-center pl-4 pr-5 py-3">
                                                            <ClientIcon v-if="values?.email_to?.length && sendToRole == 'client'" />
                                                            <RefereeIcon v-if="values?.email_to?.length && sendToRole == 'referee'" />
                                                            <CandidateIcon v-if="values?.email_to?.length && sendToRole == 'candidate'" />
                                                            <OtherIcon v-if="values?.email_to?.length && sendToRole == 'others'" />
                                                            <p>{{ values?.email_to?.length ? sendToRole === 'candidate' ? 'subject' : sendToRole : sendToRole='' }}</p>
                                                        </div>
                                                        <ArrowIcon class="cursor-pointer" />
                                                    </div>

                                                    <span v-if="roleOptions"
                                                        class="status-dropdown absolute right-0 z-50 cursor-pointer top-12">
                                                        <ul
                                                            class="bg-white border-0 shadow-md list-none rounded-b-xl p-0 flex flex-col items-tart overflow-hidden">
                                                            <!-- <li class="statusList statusList__roles inline-flex gap-2 items-center border-b"
                                                                @click="handleRoles('referee')">
                                                                <RefereeIcon />
                                                                <p class="m-0 p-0">Referee</p>
                                                            </li> -->
                                                            <li class="statusList statusList__roles inline-flex gap-2 items-center border-b"
                                                                @click="handleRoles('client')">
                                                                <ClientIcon />
                                                                <p class="m-0 p-0">Client</p>
                                                            </li>
                                                            <li class="statusList statusList__roles inline-flex gap-2 items-center border-b"
                                                                @click="handleRoles('candidate')">
                                                                <CandidateIcon />
                                                                <p class="">Subject</p>
                                                            </li>
                                                            <!-- <li class="statusList statusList__roles inline-flex gap-2 items-center"
                                                                @click="handleRoles('others')">
                                                                <OtherIcon />
                                                                <p class="m-0 p-0">Others</p>
                                                            </li> -->
                                                        </ul>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="input-container w-4/5 flex items-center">
                                        <div class="label-part w-28 h-12 gray-bg pl-5 flex items-center relative">
                                            <p class="m-0 p-0 text-sm font-medium">From</p>
                                            <div
                                            class="icon-part absolute grid place-content-center -right-8 h-16 w-16 gray-bg z-10 border-4 border-white rounded-full"
                                            >
                                            <FromIcon />
                                            </div>
                                        </div>
                                        
                                        <div class="input-part flex-1 max-h-14 relative">
                                            <neo-input
                                            type="text"
                                            class=""
                                            placeholder="Email address"
                                            bg="#f2f2f2">
                                            </neo-input>
                                        </div>
                                    </div> -->
                                    <transition name="scale">
                                        <div v-if="settings.cc" class="input-container w-full flex items-start">
                                            <div class="label-part w-28 h-12 gray-bg pl-5 flex items-center">
                                                <label for="" class="m-0 p-0 text-sm font-medium">Cc</label>
                                            </div>
                                            <div class="input-part flex-1 max-h-14">
                                                <FormulateInput validation="^required" class="w-full relative" type="text"
                                                    placeholder="Cc" v-model="values.cc" />
                                            </div>
                                        </div>
                                    </transition>
                                    <transition name="scale">
                                        <div v-if="settings.bcc" class="input-container w-full flex items-start">
                                            <div class="label-part w-28 h-12 gray-bg pl-5 flex items-center">
                                                <label for="" class="m-0 p-0 text-sm font-medium">Bcc</label>
                                            </div>
                                            <div class="input-part flex-1 max-h-14">
                                                <FormulateInput validation="^required" class="w-full relative" type="text"
                                                    placeholder="Bcc" v-model="values.bcc" />
                                            </div>
                                        </div>
                                    </transition>
                                    <fieldset :disabled="settings.reply">
                                        <div class="input-container flex items-start">
                                            <!-- <div class="label-part w-28 h-12 gray-bg pl-5 flex items-center">
                                                <label for="" class="m-0 p-0 text-sm font-medium">Subject</label>
                                            </div> -->
                                            <div class="input-part flex-1 max-h-14 subject-text-area">
                                                <!-- <FormulateInput
                                                    validation="required"
                                                    class="w-full relative"
                                                    :class="{ 'text-gray-500': settings.reply }"
                                                    type="text"
                                                    placeholder="Subject"
                                                    v-model="values.subject"
                                                    :validation-messages="{
                                                        required: 'Subject is required',
                                                    }"
                                                /> -->
                                                <CustomInput label-width="132px" custom-class="gray-bg" ref="subject"
                                                    placeholder="Subject" type="text" v-model="values.subject"
                                                    :isQuill="true">
                                                    <span class="text-sm font-medium"> Subject </span>
                                                </CustomInput>
                                                <span style="color: #960505" class="text-xs font-light leading-6 ml-32"
                                                    v-if="hasEmailErrors.subject">Subject is required</span>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="reply-fields flex items-center gap-4">
                                    <checkbox-circle class="w-12 h-12" v-model="settings.cc">Cc</checkbox-circle>
                                    <checkbox-circle class="w-12 h-12" v-model="settings.bcc">Bcc</checkbox-circle>
                                </div>
                            </div>
                            <div class="note-text-area pl-6 gap-4 flex flex-col flex-1 overflow-y-auto items-start w-full">
                                <quill-editor ref="myQuillEditor" :options="editorOptions">
                                    <div id="toolbar" slot="toolbar" class="relative">
                                        <div class="absolute left-64 pl-4 top-2.5">
                                            <font-awesome-icon ref="insertTable" icon="table" size="1x"
                                                class="cursor-pointer text-black hover:text-blue-600"
                                                @click="insertTable()" />
                                        </div>
                                    </div>
                                </quill-editor>
                                <span class="text-xs text-error font-light leading-6" v-if="hasEmailErrors.content">Content
                                    is required</span>
                                <!-- <Editor
                                    v-model="content"
                                    ref="myQuillEditor"
                                    :editorOptions="editorOptions"
                                /> -->
                            </div>
                        </div>
                    </div>
                    <footer class="flex items-center justify-between mb-0 pr-6">
                        <div
                            class="footer-left-actions h-14 border-none rounded text-sm flex items-center bg-primary text-white-text">
                            <span class="cursor-pointer p-4 relative" title="Attachments" @click="openEmailAttachments">
                                <EncloseIcon />
                                <span v-if="attachedFiles.length"
                                    class="absolute top-2 right-2 bg-white text-black rounded-full w-4 h-4 p-1 grid place-content-center">{{
                                        attachedFiles.length }}</span>
                            </span>

                            <span class="cursor-pointer p-4" title="Delete">
                                <DelIcon @click="resetEmailAttachments" />
                            </span>
                        </div>
                        <div  v-if="checkPermission('case.actions_internal')" class="flex items-center mx-2">
                            <div class="flex items-center">
                                <input id="link-checkbox" type="checkbox" v-model="actions.task"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 cursor-pointer mr-2" />
                                <label for="link-checkbox" class="cursor-pointer text-xs font-medium text-gray-500">Create
                                    task</label>
                            </div>
                            <!-- <date-picker v-model="actions.date" :disabled="!actions.task" class=" ml-3 w-20" placeholder="Date" :class="{'date-error':hasEmailErrors.date}"/>
                                <date-picker v-model="actions.time" :disabled="!actions.task" popup-class="time-picker" class="ml-3" type="time" placeholder="Time" :show-minute="false" :show-second="false" format="HH" :class="{'time-error':hasEmailErrors.time}" />
                                <dv-dropdown
                                    class="ml-3"
                                    width="180px"
                                    placeholder="Assignee"
                                    :colors="{
                                        bg: '#fff',
                                        text: '#282828',
                                        border: hasEmailErrors.task_assignee_id ? '#F44336':'#d3d3d3',
                                        svg: '#0D69D5',
                                    }"
                                    :options="assigneeOptions"
                                    openDirection="top"
                                    :config="{'label': 'user_name','track-by': 'id'}"
                                    @input="(value) => onAssigneChange(value)"
                                    :calculatedValue="actions.task_assignee_id"
                                    :disabled="!actions.task"
                                /> -->
                            <span :title="actions.notes"
                                class="bg-gray-500 transition-all duration-200 cursor-pointer px-2 py-1 hover:bg-gray-600 flex items-center justify-center rounded-full ml-3 h-8"
                                @click="openEmailNotes"
                                :class="{ 'bg-blue-500 hover:bg-blue-600': !!actions.notes, 'cursor-not-allowed opacity-70': !actions.task, 'border-2 border-solid border-red-500': hasEmailErrors.notes }">
                                <!-- background-color: rgb(13, 105, 213); -->
                                <NotesIcon />
                            </span>
                        </div>
                        <div class="relative flex items-center">
                            <!-- <span v-if="sendOptions" class="status-dropdown absolute -left-2 -top-24 z-50 cursor-pointer">
                                <ul class="bg-white border-0 list-none rounded-t-xl p-0 flex flex-col items-tart overflow-hidden"
                                    style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4)">
                                    <li class="statusList inline-flex gap-2 items-center border-b" @click="sendEmail()">
                                        <SendIcon />
                                        <p class="m-0 p-0">Send</p>
                                    </li>
                                    <li class="statusList inline-flex gap-2 items-center">
                                        <SendLater />
                                        <p class="m-0 p-0">Send Later</p>
                                    </li>
                                </ul>
                            </span>
                            <neo-button text="Send" type="submit"
                                class="send-mail-btn w-32 text-sm h-9 font-semibold rounded-xl mr-10 px-6"
                                @click="method('send-mail')" /> -->
                            <!-- {{isLoading}} -->
                            <loader v-show="isLoading" class="mr-2" />
                            <neo-button v-if="checkPermission('case.actions_external')" text="Send" @click="submitForm()"
                                class="send-mail-btn w-32 text-sm font-semibold rounded-xl px-4 py-2"
                                :disabled="isLoading" />
                        </div>
                    </footer>
                    <dialog-modal title="Browse Template" :open="isBrowseDirModalOpen" :toggleModal="browseToggleModal">
                        <BrowseDir :dirList="emailTemplates" :showCreateFolder="false" :clickedTemplate="selectTemplate"
                            :isRowClickable="true" :isModalOpen="isBrowseDirModalOpen" />
                    </dialog-modal>
                    <dialog-modal title="Browse Sequence" :open="isSeqBrowseDirModalOpen"
                        :toggleModal="browseSeqToggleModal">
                        <BrowseDir :dirList="sequenceTemplates" :showCreateFolder="false" :clickedTemplate="selectSequence"
                            :sequence="true" :isRowClickable="true" :isModalOpen="isSeqBrowseDirModalOpen" />
                    </dialog-modal>
                    <div class="flex flex-col items-center justify-center gap-10 relative">
                        <Sequence :open.sync="isSequenceModalOpen" ref="sequenceModal"
                            :dirList="sequenceTemplates" :templatesData="allSequenceTemplates" @sequenceTempAfterSendingEmail="sequenceTempAfterSendingEmail"
                            :isEmailSend="true" :isDisabled="true" @update:open="onHideSeqModal" :emailTo="emailTo" @allSequenceData="allSequenceData"
                            @on-send-email="onSendEmail" :isShowStopSequence="isShowStopSequence" :currSeqAllTemplates="currSeqAllTemplates"/>
                    </div>
                </FormulateForm>
            </div>
        </template>
    </ModalContent>
</template>

<script>
import { quillEditor, Quill } from "@shared/lib/vue-quill-editor";
import { ImageExtend } from "quill-image-extend-module";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { format } from "date-fns";
import axios from "@/axios";
import QuillBetterTable from "quill-better-table";
Quill.register("modules/ImageExtend", ImageExtend);
Quill.register(
    {
        "modules/better-table": QuillBetterTable,
    },
    true
);
var Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block);

// TODO reusable components to @shared
import checkboxCircle from "@/components/checkbox-circle/index.vue";
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
// import DvDropdown from '@/components/dropdown-base';
import Button from "@/components/button/index.vue";
import BrowseDir from "@/components/browse-dir";

import MailIcon from "@/assets/icons/profile-view/wEmail.svg";
import ToIcon from "@/assets/icons/profile-view/paperPlane.svg";
import ClientIcon from "@/assets/icons/profile-view/client.svg";
import RefereeIcon from "@/assets/icons/profile-view/refree.svg";
import CandidateIcon from "@/assets/icons/profile-view/candidate.svg";
import OtherIcon from "@/assets/icons/profile-view/other-role-icon.svg";
import ArrowIcon from "@/assets/icons/profile-view/arrow.svg";
import EncloseIcon from "@/assets/icons/profile-view/enclosure-icon-white.svg";
import DelIcon from "@/assets/icons/svg/delete-icon-white.svg";
import NotesIcon from "@/assets/icons/profile-view/wnotes.svg";
import CustomInput from "@/components/custom-input";
import { checkPermission } from "@shared/utils/functions"

import Dialog from "@/components/dialog";
const ModalContent = () => import("@shared/modal-content");
const Loader = () => import("@/components/loader");
import Sequence from "@/components/sequence";
// import { uniq } from "lodash";
export default {
    name: "mail-modal",
    components: {
        quillEditor,
        MailIcon,
        ToIcon,
        ClientIcon,
        RefereeIcon,
        CandidateIcon,
        OtherIcon,
        ArrowIcon,
        "dialog-modal": Dialog,
        checkboxCircle,
        EncloseIcon,
        DelIcon,
        // DatePicker,
        // DvDropdown,
        NotesIcon,
        CustomInput,
        "neo-button": Button,
        BrowseDir,
        ModalContent,
        Loader,
        Sequence,
        // renameFolderModal,
    },
    props: {
        values: {
            type: Object,
            required: true,
        },
        actions: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        },
        attachedFiles: {
            type: [Array, FileList],
            default: () => [],
        },
        emailTemplates: {
            type: Array,
            default: () => [],
        },
        sequenceTemplates: {
            type: Array,
            default: () => [],
        },
        allSequenceTemplates: {
            type: Array,
            default: () => []
        },
        assigneeOptions: {
            type: Array,
            default: () => [],
        },
        content: {
            type: String,
            default: "",
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isSequenceModalOpen: {
            type: Boolean,
            default: false
        },
        isShowStopSequence: {
            type: Boolean,
            default: false
        },
        isSendDisabled:{
            type: Boolean
        },
        currSeqAllTemplates:{
            tyle: Array,
            default: () => []
        }
    },
    data() {
        return {
            isBrowseDirModalOpen: false,
            isSeqBrowseDirModalOpen: false,
            roleOptions: false,
            sendOptions: false,
            sendToRole: "",
            editorOptions: {
                debug: "info",
                placeholder: "type your text here",
                theme: "snow",
                modules: {
                    toolbar: {
                        container: [
                            [{
                                size: ["small", false, "large", "huge"]
                            }],
                            ["bold", "italic", "underline", "strike"],
                            ["clean"],
                        ],
                    },
                    table: false,
                    "better-table": {
                        operationMenu: {
                            items: {
                                // unmergeCells: {
                                //   text: 'Another unmerge cells name'
                                // }
                            },
                            color: {
                                colors: ["#fff", "red", "#d5e1df", "#e3eaa7", " #b5e7a0", "#eea29a", "#80ced6", "#f7786b", "#f4e1d2"], // colors in operationMenu
                                text: "Background Colors", // subtitle
                            },
                        },
                    },
                    keyboard: {
                        bindings: QuillBetterTable.keyboardBindings,
                    },
                },
            },
        };
    },
    computed: {
        // TODO
        /* I think I moved this block (hasEmailErrors) here by mistake.
        If all logic is still in profile-view.js that property
        also could be provided for this modal via props from
        the parent page.
        Moks Vats, don't you mind if I return it? */
        hasEmailErrors() {
            const { task, time, date, task_assignee_id, notes, isClicked } = this.actions;
            let errors = {
                notes: false,
                content: isClicked && !this.removeHTML(this.content),
                subject: isClicked && !this.removeHTML(this.values.subject),
            };
            if (task && (!time || !date || !task_assignee_id || !notes)) {
                errors = { ...errors, notes: isClicked };
            }
            return errors;
            // console.log(this.actions, "this.emailActions");
            // console.log(isClicked && (task && !notes), "(task || !!notes)");
            // return {
            // 	date: isClicked && (task && !date),
            // 	time: isClicked && (task && !time),
            // 	task_assignee_id: isClicked && (task && !task_assignee_id),
            // 	notes: isClicked && (task && !notes),
            //   content: isClicked && !this.computedContent
            // }
        },
        emailTo() {
            return this.values?.email_to.length > 0 ? this.values.email_to[0]?.email : '';
        },
        updatedEmailTo() {
            const updatedValues = { ...this.values };
            let emailTo = updatedValues?.email_to.length > 0 ? updatedValues.email_to[0]?.email : '';
            if (emailTo && emailTo === "-") {
                updatedValues.email_to = [];
            }
            return updatedValues.email_to;
        }
    },
    watch: {
        content(val) {
            this.replaceQuillPlaceholders(val, ".note-text-area .ql-editor");
        },
        // "values.subject"(val) {
        //     this.$refs["subject"]?.replaceQuillPlaceholders(val);
        // },
        isSearchModal: function (val) {
            if (this.isResourceModalOpen !== val) {
                this.isResourceModalOpen = val;
                if (val) this.sequenceCreateMode = val
            }
        },
        isResourceModalOpen: function (val) {
            if (this.isSearchModal !== val) {
                this.$emit("onSearchModalClose");
            }
        },
        isDirModalOpen: function (val) {
            this.isBrowseDirModalOpen = val;
        },
        isBrowseDirModalOpen: function (val) {
            if (this.isDirModalOpen !== val) {
                this.$emit("onDirModalClose");
            }
            this.directoryToastMessage = null;
        },
        isSequenceModalOpen: function () {
            if (!this.isSequenceModalOpen) {
                this.sequenceTemplates = [];
                this.sequenceCreateMode = false
            }
        }
    },
    mounted() {
        this.closeModal();
    },
    methods: {
        checkPermission,
        updateEditableSeqOnModalOpen(val){
            this.editableSequence = val;
        },  
        sequenceTempAfterSendingEmail(val){
            this.$emit('sequenceTempAfterSendingEmail',val);
        },
        allSequenceData(value){
            this.allSequenceTemplates = value;
        },
        updateContent(quill) {
            this.$emit("update:content", quill?.root?.innerHTML);
        },
        closeList() {
            this.roleOptions = false;
            this.sendOptions = false;
        },
        closeModal() {
            this.closeList();
            this.$modal.hide("mail");
        },
        submitForm() {
            const quill = this.$refs?.myQuillEditor?.quill;
            if (quill) this.updateContent(quill);
            setTimeout(() => {
                this.$emit("submit", this.closeModal);
            }, 100);
        },
        browseToggleModal() {
            this.isBrowseDirModalOpen = !this.isBrowseDirModalOpen;
        },
        async browseSeqToggleModal() {
            if (!this.values?.email_to?.length) {
                this.$toast.error("Email ID is required to open sequence!")
                return
            }
            try {
                const { data } = await axios.get(`/email-sequence/list?external_id=${this.$store.getters.getTenantId}`);
                // console.log('data :>> ', data);
                this.sequenceTemplates = data.content.map((seq) => ({
                    ...seq,
                    created_at: seq?.created_at ? format(new Date(seq?.created_at), "MMM dd yyyy") : "",
                    // type: "resource",
                }));
            } catch (error) {
                console.log(error, "[+] failed to fetch sequence");
            }
            this.isSeqBrowseDirModalOpen = !this.isSeqBrowseDirModalOpen; 
        },
        async selectTemplate(payload) {
            if (payload.type === "resource") {
                await this.$emit("select:template", payload);
                this.browseToggleModal();
            }
        },
        selectSequence(payload) {
            this.$emit("on-select-sequence", payload);
            this.isSequenceModalOpen = false;
            this.browseSeqToggleModal();
        },
        toggleRoles() {
            this.roleOptions = !this.roleOptions;
        },
        handleRoles(role) {
            this.sendToRole = role;
            this.roleOptions = false;
            this.$emit("select:role", role);
        },
        resetEmailAttachments() {
            this.$emit("reset:file");
        },
        openEmailNotes() {
            this.$emit("openEmailNotes");
        },
        insertTable() {
            const betterTable = this.$refs.myQuillEditor.quill.getModule("better-table");
            betterTable.insertTable(3, 3);
        },
        replaceQuillPlaceholders(content, query) {
            // let placeholders = content.match(/\{{(.*?)\}}/g);
            // const tags = content.match(/<a(.*?)>/g);
            // const quill = this.$refs.myQuillEditor?.quill;

            // placeholders = uniq(
            //     placeholders?.filter((placeholder) => {
            //         return !tags?.some((tag) => tag.includes(placeholder));
            //     })
            // );

            var editor = document.querySelector(query);
            if (editor) editor.innerHTML = content;

            // for (const index in placeholders) {
            //     if (quill) {
            //         const text = placeholders[index];
            //         let content = quill.getText();
            //         let sub_content = quill.getText();
            //         let start = 0;
            //         while (sub_content.indexOf(text) > -1) {
            //             start += sub_content.indexOf(text);
            //             const length = text?.length;
            //             this.formatText(quill, start, length);
            //             this.removeFormat(quill);
            //             start += length;
            //             sub_content = content.substr(start, content.length);
            //         }
            //     }
            // }
        },

        formatText(quill, start, length) {
            quill.formatText(start, length, {
                color: "rgb(13, 105, 213)",
                bold: true,
            });
        },
        removeFormat(quill) {
            quill.format("bold", false);
            quill.format("color", "black");
        },
        removeHTML(str) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = str;
            return tmp.textContent || tmp.innerText || "";
        },
        onHideSeqModal(event) {
            this.$emit("hide-seq-modal", event)
        },
        onSendEmail(event) {
            this.$emit("on-send-email", { event: event, closeModal: this.closeModal })
        },
        openEmailAttachments() {
            this.$emit("openEmailAttachments")
        },
    },
};
</script>

<style lang="scss" scoped>
// TODO check if all necessary styles are here
// TODO move common styles to the wrapper component
.gray-bg {
    background-color: #f2f2f2;
}

.footer-left-actions {
    @apply bg-primary;
    border-top-right-radius: 24px;
}

.statusList {
    padding: 12px 16px;
    text-align: left;
    vertical-align: middle;
    transition: background-color 300ms, border-right 100ms;
    position: relative;

    &:hover {
        background-color: #cce3ff;
    }

    &:hover::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 4px;
        background-color: #3b2cd4;
        right: 0;
        top: 0;
    }

    &__roles {
        padding-left: 1rem;
        padding-right: 3.5rem;
    }
}

.mx-datepicker {
    width: 110px !important;
}

::v-deep {
    .custom-quill {

        // width: calc(100% - 48px) !important;
        .ql-toolbar {
            display: none !important;
        }

        .ql-editor.ql-blank::before {
            color: #9fa6b1 !important;
            content: attr(data-placeholder);
            font-style: normal !important;
            left: 0 !important;
            right: 0 !important;
            line-height: 24px !important;
            // padding: 13px 0px!important;
        }

        .ql-editor {
            line-height: 24px !important;
            padding: 12px 0px !important;

            p {
                font-style: normal !important;
            }
        }

        .quill-editor {
            width: 100% !important;
            height: 100% !important;
        }

        .ql-container {
            height: 100% !important;
            font-size: inherit !important;
            font-family: inherit !important;
            line-height: inherit !important;
        }
    }

    .ql-toolbar {
        .ql-clean {
            margin-left: -16px;
        }
    }

    .mx-input {
        height: 1.75rem !important;
        border-radius: 0.5rem !important;
    }

    .up-btn {
        svg {
            path {
                fill: #0d69d5;
                stroke: #0d69d5;
            }
        }
    }

    .ql-editor {
        white-space: normal !important;

        br {
            // display: none!important;
        }

        .placeholder {
            color: rgb(13, 105, 213);
            font-style: bold;
        }
    }

    .note-text-area {
        height: calc(100% - 220px);
    }
}

.date-error ::v-deep .mx-input {
    border: 1px solid #f44336 !important;
}

.time-error ::v-deep .mx-input {
    border: 1px solid #f44336 !important;
}

.loader {
    height: 1.5rem !important;
    width: 1.5rem !important;
}</style>
