<template>
<div
    class="status-colored border border-solid border-gray-300"
    :style="currentStyles"
>
    <VueMultiselect
        :options="options"
        track-by="id"
        label="label"
        :multiple="false"
        :searchable="false"
        :allowEmpty="false"
        selectLabel=""
        deselectLabel=""
        :preselectFirst="true"
        v-model="status"
        @input="$emit('input', $event)"
        :style="{color: currentStyles.color}"
    >
        <template #caret="{toggle}">
            <div
                class="status-colored__caret"
                @mousedown.prevent.stop="toggle()"
                :style="{backgroundColor: currentStyles.backgroundColor}"
            >
                <dropdownIcon
                    :fill="currentStyles.color"
                />
            </div>
        </template>
    </VueMultiselect>

</div>
</template>

<script>
import VueMultiselect from "@/components/vue-multiselect";
import dropdownIcon from "@/assets/icons/sc-journey/dropdown.svg";

export default {
    name: 'select-colored',
    components: {
        VueMultiselect,
        dropdownIcon,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            status: null,
        }
    },
    created() {
        this.status = this.options.find(el => el.label === this.value);
    },
    computed: {
        currentClass() {
            return 'select-colored--' + this.value;
        },
        currentStyles() {
            return this.status?.styles || {};
        },
    },
}
</script>

<style lang="scss" scoped>
.status-colored {
    position: relative;
    // width: 120px;
    // height: 24px;
    border-radius: 12px;
    cursor: pointer;
    &__caret {
        display: inline-flex;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 10px;
        padding: 5px 1px;
        z-index: 1;
        svg {
            transition: transform .2s;
        }
    }
    ::v-deep {
        .multiselect {
            outline: none;
            min-height: unset;
            &--active {
                .status-colored__caret {
                    svg {
                        transform: rotate(-180deg);
                    }
                }
            }
            .multiselect__placeholder {
                font-size: 0.775rem;
                font-weight: 500;
                color: #282828;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .multiselect__content-wrapper {
            width: auto;
            overflow: auto;
        }
        .multiselect__content-wrapper::-webkit-scrollbar{
            display: none;
        }
        .multiselect__tags {
            min-height: unset;
            background: none;
            border: none;
            width: 100%;
            box-sizing: border-box;
            padding: 0 23px 0 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        .multiselect__single {
            background: none;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
        }
        .multiselect__select {
            z-index: 1;
        }
        .multiselect__option {
            font-size: 12px;
            min-height: 20px;
            box-sizing: border-box;
            padding: 10px;
            color: #282828;
        }
        .multiselect__placeholder {
            margin: 0;
        }
    }
}
</style>